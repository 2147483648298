import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { Worksheet } from '@shared/models/worksheet/Worksheet';
import { Observable } from 'rxjs';
import { CropNutrientWorksheet } from './model/crop-nutrient-worksheet.model';
import { CropSubType } from './model/crop-sub-type';
import { CropType } from './model/crop-type';
import { CropYieldAverage } from './model/crop-yield-average';
import { NitrogenCredit } from './model/nitrogen-credit';
import { SoilTexture } from './model/soil-texture';

@Injectable({
  providedIn: 'root'
})
export class CropNutrientService {

  constructor(private httpClient: HttpClient) { }

  get(id: string): Observable<CropNutrientWorksheet> {
    const url = `${URLs.getEndPoint(WorksheetKeys.CROP_NUTRIENT)}/${id}`;
    return this.httpClient.get(url) as Observable<CropNutrientWorksheet>;
  }

  // TODO changes this to use token to retrieve list worksheets
  getAll(azureUser: string): Observable<Worksheet[]> {
    const url = `${URLs.WORKSHEET_ENDPOINT}/username/${azureUser}`;
    return this.httpClient.get(url) as Observable<Worksheet[]>;
  }

  delete(id: string): Observable<any> {
    const url = `${URLs.getEndPoint(WorksheetKeys.CROP_NUTRIENT)}/${id}`;
    return this.httpClient.delete(url) as Observable<any>;
  }

  save(worksheet: CropNutrientWorksheet): Observable<any> {
    const url = URLs.getEndPoint(WorksheetKeys.CROP_NUTRIENT);
    return this.httpClient.post(url, worksheet) as Observable<any>;
  }

  update(worksheet: CropNutrientWorksheet): Observable<any> {
    const url = `${URLs.getEndPoint(WorksheetKeys.CROP_NUTRIENT)}/${worksheet.id}`;
    return this.httpClient.put(url, worksheet) as Observable<any>;
  }

  updateWorksheetFlat(worksheet: Worksheet): Observable<Worksheet> {
    const url = `${URLs.WORKSHEET_ENDPOINT}/flat/${worksheet.id}`;
    return this.httpClient.put(url, worksheet) as Observable<Worksheet>;
  }

  allSoilTextures$() {
    return this.httpClient.get(URLs.SOIL_TEXTURES_ENDPOINT) as Observable<SoilTexture[]>;
  }

  allCropTypes() {
    return this.httpClient.get(URLs.CROP_TYPES_ENDPOINT) as Observable<CropType[]>;
  }

  allCropSubTypes() {
    return this.httpClient.get(URLs.CROP_SUB_TYPES_ENDPOINT) as Observable<CropSubType[]>;
  }

  allNitrogenCredits() {
    return this.httpClient.get(URLs.CROP_NITROGEN_CREDITS_ENDPOINT) as Observable<NitrogenCredit[]>;
  }

  cropYieldAverage(cropId: number, geoTownShipName: string, upperTierCode: number): Observable<CropYieldAverage> {
    const url = `${URLs.CROP_YIELD_AVERAGE_ENDPOINT}?crop-id=${cropId}&geotownship=${geoTownShipName}&upper-tier-code=${upperTierCode}`;
    return this.httpClient.get(url) as Observable<CropYieldAverage>;
  }
}
